import forList    from "@/mixin/forList";
import ChinaAudit from "./ChinaAudit.vue";
import AusAudit   from "./AusAudit.vue";
import { searchTypeEnum } from "../orderList/config";
import { dateTypeEnum, stateEnum, actionList } from "./config";
const tabEnum = [
	{
		name: "全部",
		value: ""
	},
	stateEnum[0],
    stateEnum[1],
    stateEnum[2],

]
export default {
	name: "CommissionList",
	noFetchFirst: true,
	mixins: [forList],
	components: {ChinaAudit, AusAudit},

	data() {
		return {
			searchTypeEnum,       // 模糊查询字段选项
			stateEnum,            // 状态
			tabEnum,
			dateTypeEnum,         // 供查询的日期类型选项
			SP_: {
				searchType        : 1,
				searchValue       : "",
				searchCheckState  : "",
				searchTimeType    : 1,
				searchStartTime   : "",
				searchEndTime     : "",
			},
			showChinaAudit        : false, // 中国区审核
			showAusAudit          : false, // 澳洲取审核
            showTip: true,
            showPicDia: false,
            showOrderCommRecord: false,
            orderCommRecordList: [],
            updateMoneyCheckStateDia: false,


            PB: {
                state: '',
                calMonth: '',
                remark: "",


                // performanceEmpStr:'',
                // seasonId: "",
                // seasonProfit: "",
                // // bonusMoney   : "",
                // // commMoney    : "",
                // payableBonus: '',
                // chanMoney: '',

                // guaranteeMoney: '',
                // pkRatioMoney: '',
                // victoryMoney: '',
            },
            rules: {
                state: { required: true, message: "请选择审核状态", trigger: "blur" },
                calMonth: { required: true, message: "请选择绩效核算月份", trigger: "blur" },
            },
            clickRow: '',
            performanceEmpInfo: '',


		};
	},

	computed: {
		timeType () {
			const {searchTimeType} = this.SP_;
			return dateTypeEnum.find(({value}) => value===searchTimeType) || dateTypeEnum[0];
		}
	},

	watch : {
        "SP_.searchCheckState" (val) {
            this.handleSearch()
        }
    },

	created () {
		this.auth.chinaAudit   = this.$hasAuthFor("api/orderComm/updateOneCheckState");
		this.auth.ausAudit     = this.$hasAuthFor("api/orderComm/updateTwoCheckState");
		this.auth.delete       = this.$hasAuthFor("api/orderComm/deleteOrderCommApply");
		this.auth.exportOrder  = this.$hasAuthFor("api/orderComm/downOrderComm");
		this.auth.detail       = this.$hasAuthFor("api/orderComm/queryOrderCommInfo");
		this.auth.orderDetail  = this.$hasAuthFor("api/order/queryOrderDetails");

        this.auth.sqDetail = this.$hasAuthFor("api/orderComm/queryOrderCommRecordList");




        this.auth.rRevokeApply = this.$hasAuthFor("api/orderComm/revokeApply");
        this.auth.updateMoneyCheckState = this.$hasAuthFor("api/orderComm/updateMoneyCheckState");


        // actionList.forEach(action => {
        //     action.banned = !this.auth[action.name]
        // });

		const {params} = this.$route;
		if (Object.keys(params).length) {
			for (let key in params) {
				if (key === "searchHrefType") this.searchHrefType = params[key];
				else if (Object.prototype.hasOwnProperty.call(this.SP_,key))
					this.SP_[key] = params[key]
			}
			this.handleSearch();
		}
		else this.fetchData();
	},

	methods: {
        // 点击 表格行的操作按钮
        handleAction(action, scope) {  //待改
            const type = action.name;
            const data = scope.row;
            switch (type) {
                case "handleRevokeApply":
                    this.handleRevokeApply(data)
                    break;
                case "handleUpdateMoneyCheckState":
                    this.handleUpdateMoneyCheckState(data)
                    break;

                case "handleOrderCommRecord":
                    this.handleOrderCommRecord(data)
                    break;

                case "handleOrderDetail":
                    this.handleOrderDetail(data)
                    break;
                case "handleCommissionDetail":
                    this.handleCommissionDetail(data)
                    break;
                default:
                    console.log("有没有操作处理程序");
                    break;
            }
        },
        // 点击撤销
        async handleRevokeApply(row) {
            let loadingRef;
            try {
                await this.$confirm(`您是否要撤销${row.buyName}客户订单的业绩审核？`, "撤销业绩审核")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/orderComm/revokeApply",
                    method: "post",
                    data: {
                        id: row.id
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "撤销成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "撤销失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        handleClose() {
            this.updateMoneyCheckStateDia = false
            // this.resetData();
            setTimeout(() => {
                this.$refs.form.clearValidate();
            }, 20);
            // this.$emit("update:show", false);
        },

        async handUpdateMoneyCheckState() {
            const loadingRef = this.$loading({
                target: ".updateMoneyCheckStateDia .el-dialog"
            });
            let obj = {
                subscribeName: this.clickRow.buyName,
                subscribeMoney: this.clickRow.contractMoney,
                calNum: this.performanceEmpInfo.calNum,
                saleEmp: this.performanceEmpInfo.workEmpId,
                saleCharge: this.performanceEmpInfo.workChargeId,
                saleManager: this.performanceEmpInfo.workManageId,
                isCooperation: this.performanceEmpInfo.isCooperation,
                coopEmp: this.performanceEmpInfo.cooperationEmpId,
                coopCharge: this.performanceEmpInfo.cooperationChargeId,
                coopManager: this.performanceEmpInfo.cooperationManageId,
            }
            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/orderComm/updateMoneyCheckState",
                    method: "post",
                    data: {
                        ...this.PB,
                        id: this.clickRow.id,
                        performanceEmpStr: JSON.stringify(obj)
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    // this.$emit("change");
                    this.handleClose();
                    this.fetchData()

                } else if (res.code !== 1003)
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
        handleUpdateMoneyCheckState(row) {
            this.clickRow = row
            this.queryPerformanceCalData(row)
            this.PB.calMonth = this.clickRow.commCheckTimeName
            this.updateMoneyCheckStateDia = true

        },
        async queryPerformanceCalData(data) {
            let loadingRef = this.$loading({
                target: ".updateMoneyCheckStateDia .el-dialog"
            });

            try {
                const res = await this.$axios({
                    url: "/api/order/queryOrderSaleAndCoopInfo",
                    method: "post",
                    data: {
                        type: 1, //0：提交绩效审核 1：薪酬审核
                        orderId: data.orderId
                    }
                })
                if (res.code === 2000) {
                    this.performanceEmpInfo = res.data.data
                    loadingRef && loadingRef.close();
                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "获取失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        async handleOrderCommRecord(row) {
            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/orderComm/queryOrderCommRecordList",
                method: "post",
                data: {
                    orderCommId: row.id
                }
            });
            if (res.code === 2000) {
                this.showOrderCommRecord = true
                this.orderCommRecordList = res.data.orderCommRecordList
                // this.total = res.data.count;
                // const list = res.data.orderCommApplyList.map(item => {
                //     const state = this.stateEnum.find(({ value }) =>
                //         value === item.checkState)
                //     item.checkStateName = state?.name || "";
                //     item.commCheckTimeName = item.commCheckTimeName?.slice(0, 7)
                //     return item;
                // });
                // this.tableData = list;

                loading.close();
            } else if (res.code !== 1003)
                throw res

        },
        showPic() {
            this.showPicDia = true
        },
        clearSP_trim () {
            this.SP_.searchType = 1,
            this.SP_.searchTimeType = 1;
        },

		async fetchData() {
			const loading = this.$loading();

			try {	
				const res = await this.$axios({
					url: "/api/orderComm/queryOrderCommApplyList",
					method: "post",
					data: {
						...this.extractParams(),
						searchCheckDest   : ""
					}
				});

				if (res.code === 2000) {
					this.total = res.data.count;
					const list = res.data.orderCommApplyList.map(item => {
						const state = this.stateEnum.find(({value}) =>
							value === item.checkState)
						item.checkStateName = state?.name || "";
						item.commCheckTimeName = item.commCheckTimeName?.slice(0,7)


                        const _actionList = [...actionList];
                        item.actionList = _actionList;
                        if (!this.auth.orderDetail) {
                            const deleteActionIndex = item.actionList.findIndex(({ name }) => name == "handleOrderDetail");
                            if (deleteActionIndex >= 0) item.actionList.splice(deleteActionIndex, 1)
                        }
                        if (!this.auth.detail || item.checkState != 3) {
                            const deleteActionIndex2 = item.actionList.findIndex(({ name }) => name == "handleCommissionDetail");
                            if (deleteActionIndex2 >= 0) item.actionList.splice(deleteActionIndex2, 1)
                        }

                        if (!this.auth.rRevokeApply || (item.checkState != 1 && item.checkState != 2 && item.checkState != 4)) {
                            const deleteActionIndex3 = item.actionList.findIndex(({ name }) => name == "handleRevokeApply");
                            if (deleteActionIndex3 >= 0) item.actionList.splice(deleteActionIndex3, 1)
                        }
                        if (!this.auth.updateMoneyCheckState || (item.checkState != 4)) {
                            const deleteActionIndex4 = item.actionList.findIndex(({ name }) => name == "handleUpdateMoneyCheckState");
                            if (deleteActionIndex4 >= 0) item.actionList.splice(deleteActionIndex4, 1)
                        }
                        if (!this.auth.sqDetail) {
                            const deleteActionIndex5 = item.actionList.findIndex(({ name }) => name == "handleOrderCommRecord");
                            if (deleteActionIndex5 >= 0) item.actionList.splice(deleteActionIndex5, 1)
                        }





						return item;
					});
					this.tableData = list;

					loading.close();
				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();	
			}
		},

		handleChinaAuditBtn () {
            if (this.checkedlist.some(({ checkState }) => checkState !== 1))
                return this.$message({
                    message: "请选择待中国区审核的申请",
                    type: "error"
                })
			this.showChinaAudit = true;
		},
		handleAusAuditBtn () {
            if (this.checkedlist.some(({ checkState }) => (checkState !== 2)))
				return this.$message({
                    // message: "请选择待澳洲区审核或审核通过的申请",
                    message: "请选择待澳洲区审核的申请",

					type: "error"
				})
			this.showAusAudit = true;
		},

        // 点击导出订单
        async handleExportBtn (flag) {
			if (!this.SP_.searchStartTime && !this.SP_.searchEndTime)
				return this.$message({
					message: "请选择时间范围",
					type: "warning"
				});

			let loadingRef,
				url = "/api/orderComm/downOrderComm";
			try {
				await this.$confirm("确定导出吗", "系统提示")
				loadingRef = this.$loading();

                const data = this.extractParams(true);
                const res = await this.$axios({
                    method: "post", url, data })

				if (res.code === 2000) {
					this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
					loadingRef && loadingRef.close();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
				}
				loadingRef && loadingRef.close();
			}
        },
		
		// 点击快速筛选
        handleTabClick (state) {
            if (state !== this.SP_.searchCheckState) {
                this.SP_.searchCheckState = state;
            }
        },

		handleOrderDetail (item) {
			const url = new URL(location.href);
			url.hash = `/core/order/detail/${item.orderId}`
			window.open(url)
		},

		handleCommissionDetail (item) {
			this.$router.push({
                path: `/commission/orderCommission/detail/${item.id || 5}`
			});
		},

		// 点击: 批量删除
		async handleBulkDelete() {
			let loadingRef;
			try {
				await this.$confirm("确定要删除吗", "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
					url: "/api/orderComm/deleteOrderCommApply",
					method: "post",
					data: {
						applyIds: this.checkedlist.map(
							it => it.id).join()
					}
				})
				if (res.code === 2000) {
					this.$message({
						message: "删除成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "删除失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
		}
	}
};