import forList        from "@/mixin/forList";
// import ExplainPage  from "./ExplainPage.vue";
import CheckCommission    from "./CheckCommission.vue";
import CheckingDetails    from "./CheckingDetails.vue";
import SettleCommission   from "./SettleCommission.vue";
import {
	searchTypeEnum,
	dateTypeEnum,
	checkStateEnum,
    checkStateEnum2,
} from "./config";

export default {
	name: "OutChannelCommission",
	noFetchFirst: true,
	mixins: [forList],
	components: {
		// ExplainPage,
		CheckCommission,
		CheckingDetails,
		SettleCommission
	},

	data() {
		return {
			searchTypeEnum,
			dateTypeEnum,
			checkStateEnum,
            checkStateEnum2,
			SP_: {
				searchType        : 1,
				searchValue       : "",
				searchTimeType    : 1,
				searchStartTime   : "",
				searchEndTime     : "",
                searchCheckState: "",
                searchCommState: "",

			},
			// showExplain           : false, // 状态说明
			checkingDetailsData   : null,
			checkingItem          : null,
			settleingItem         : null,
            activeTabName: 'part1',
            tabEnum: [
                {
                    name: "全部",
                    value: "part1",
                    num: '',
                },
                {
                    name: "待结佣",
                    value: "part2",
                    num: 0,

                },
                {
                    name: "已结佣",
                    value: "part3",
                    num: 1,

                },

            ]
		};
	},

	created () {
		this.auth.detail           = this.$hasAuthFor("api/order/queryOrderDetails");
        this.auth.checkingDetails = this.$hasAuthFor("outChannelCommission/checkingDetails");
		this.auth.checkcommission  = this.$hasAuthFor("api/orderChanComm/saveCheckChanComm");
		this.auth.settleCommission = this.$hasAuthFor("api/orderChanComm/saveChanCommInfo");
		this.auth.delete           = this.$hasAuthFor("api/orderChanComm/deleteChanComm");
		this.auth.exportOrder      = this.$hasAuthFor("api/orderChanComm/downloadOrderChanComm");

		const {params} = this.$route;
		if (Object.keys(params).length) {
			for (let key in params) {
				if (Object.prototype.hasOwnProperty.call(this.SP_,key))
					this.SP_[key] = params[key]
			}
			this.handleSearch();
		}
		else this.fetchData();
	},

	methods: {
        handleTabClick(value) {
            console.log(this.activeTabName);
            // if (this.activeTabName == 'part1') {
            //     this.SP_.searchCommState = ''
            // }
            // if (this.activeTabName == 'part2') {
            //     this.SP_.searchCommState = 0
            // }
            // if (this.activeTabName == 'part3') {
            //     this.SP_.searchCommState = 1
            // }
            if (value == 'part1') {
                this.SP_.searchCommState = ''
            }
            if (value == 'part2') {
                this.SP_.searchCommState = 0
            }
            if (value == 'part3') {
                this.SP_.searchCommState = 1
            }
            this.fetchData()
        },

        changeCheckState2() {
            console.log(this.SP_.searchCommState);
            if (this.SP_.searchCommState == 0) {
                this.activeTabName = 'part2'
            }
            if (this.SP_.searchCommState == 1) {
                this.activeTabName = 'part3'
            }
            this.fetchData()
        },
        clearSP_trim () {
            this.SP_.searchType = 1,
            this.SP_.searchTimeType = 1
            this.activeTabName = 'part1'

        },

		async fetchData(noloading) {
			let loading;
			if (noloading !== true) loading = this.$loading();

			try {	
				const res = await this.$axios({
					url: "/api/orderChanComm/queryOrderChanCommList",
					method: "post",
					data: {
                        ...this.extractParams(),
                        searchCommState: this.SP_.searchCommState
					}
				});

				if (res.code === 2000) {
					this.total = res.data.count;
					this.tableData = res.data.orderChanCommList;

					loading?.close();
				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading?.close();
			}
		},

		// 点击: 订单状态说明
		// handleExplainBtn () {
		// 	this.showExplain = true
		// },

		handleOrderDetail (item) {
			// const url = new URL(location.href);
			// url.hash = `/core/order/detail/${item.orderId}`
			// window.open(url)
			this.$router.push({
				path: `/core/order/detail/${item.orderId}`
			})
		},

		handleCheckBtn (item) {
			this.checkingItem = item
		},

		handleSettleBtn (item) {
			this.settleingItem = item
		},

		handleCheckingDetail (item) {
			this.checkingDetailsData = item
		},

		// 点击: 批量删除
		async handleBulkDelete() {
			let loadingRef;
			try {
				await this.$confirm("确定要删除吗", "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
					url: "/api/orderChanComm/deleteChanComm",
					method: "post",
					data: {
						ids: this.checkedlist.map(
							it => it.id).join()
					}
				})
				if (res.code === 2000) {
					this.$message({
						message: "删除成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "删除失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
		},

        // 点击导出订单
        async handleExportBtn(flag) {
            console.log(123);
            if (!this.SP_.searchStartTime && !this.SP_.searchEndTime)
                return this.$message({
                    message: "请选择时间范围",
                    type: "warning"
                });

            let loadingRef,
                url = "/api/orderChanComm/downloadOrderChanComm";
            try {
                await this.$confirm("确定导出吗", "系统提示")
                loadingRef = this.$loading();

                const data = this.extractParams(true);
                const res = await this.$axios({
                    method: "post", url, data
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                    loadingRef && loadingRef.close();
	
                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
	}
};