<!-- 添加、编辑产品 -->
<template>
    <el-dialog class="product-edit"
        :visible="viewIsReady"
        @close="handleClose"
        :title="titleText"
        width="560px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSaveBefore"
        >
            <el-form-item label="产品名称" prop="name">
                <el-input v-model.trim="PB.name"
                    maxlength="50"
                    class="width3"></el-input>
            </el-form-item>

            <el-form-item label="投资年限" prop="investment" class="wrap">
                <el-input v-model.trim="PB.investment"
                    maxlength="200"
                    type="textarea"
                    rows="5"
                    class="width3"></el-input>
                <span class="hint">年限之间用#分隔，以月为基础单位</span>
            </el-form-item>

            <el-form-item label="产品利率" prop="rate" class="wrap">
                <el-input v-model.trim="PB.rate"
                    maxlength="200"
                    type="textarea"
                    rows="5"
                    class="width3"></el-input>
                <span class="hint">产品利率之间用#分隔</span>
            </el-form-item>
            <el-form-item label="产品发行日期"
                prop="starttime"
                :rules="PB.iscom ? rules.none : rules.starttime"
                class="wrap">
                <el-date-picker
                    v-model="PB.starttime"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="产品到期日期"
                prop="endtime"
                :rules="PB.iscom ? rules.none : rules.endtime"
                class="wrap">
                <el-date-picker
                    v-model="PB.endtime"
                    @change="onEndtimeChange"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="实际到期日期"
                prop="realEndTime"
                :rules="PB.iscom ? rules.none : rules.realEndTime"
                class="wrap">
                <el-date-picker
                    v-model="PB.realEndTime"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="总发行量" prop="circulation" class="wrap">
                <el-input v-model.number="PB.circulation"
                    class="width3"></el-input>
            </el-form-item>
            <el-form-item label="起息日期"
                prop="valueDateTime"
                :rules="PB.iscom ? rules.none : rules.valueDateTime"
                class="wrap">
                <el-date-picker
                    ref='mydate'
                    v-model="PB.valueDateTime"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="是否公司债" prop="iscom">
                <el-radio-group v-model="PB.iscom">
                    <el-radio
                        v-for="item in BooleanEnum"
                        :key="item.value"
                        :label="item.value">
                        {{ item.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="备注" prop="remark">
                <el-input v-model="PB.remark"
                    maxlength="100"
                    type="textarea" rows="5"
                    class="width3"></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSaveBefore" type="primary">保存</el-button>
        </div>
        <el-dialog 
            append-to-body
            class="product-edit"
            :visible="tipDia"
            title="提示"
            @close="handleCloseT('top')"
            width="560px">
            <div>
                该产品已存在全款齐的订单，您确认要修改该产品的起息日期么？
            </div>

            <div slot="footer">
                <el-button @click="handleCloseB">取消</el-button>
                <el-button @click="handleSure" type="primary">确定</el-button>
            </div>

        </el-dialog>


    </el-dialog>

    
</template>

<script>
import {BooleanEnum} from "./config";
export default {
    name: 'ProductEdit',
    props: ["item"],

    data () {
        return {
            tipDia:false,
			BooleanEnum,  // 固定的，是/否选项
            viewIsReady: false,
            PB: {
                name: "",
                investment: "",
                rate: "",
                starttime: "",
                endtime: "",
                realEndTime: "",
                valueDateTime:'',
                circulation: "",
                iscom: 0,
                remark: ""
            },
            copy_valueDateTime:'',
            isFlag:false,
            // 验证规则
            rules: {
                name       : { required: true, message: "请输入产品名称",     trigger: "blur" },
                investment : { required: true, message: "请输入投资年限",     trigger: "blur" },
                rate       : { required: true, message: "请输入产品利率",     trigger: "blur" },
                starttime  : { required: true, message: "请选择产品发行日期", trigger: "blur" },
                endtime    : { required: true, message: "请选择产品到期日期", trigger: "blur" },
                realEndTime: { required: true, message: "请选择产品实际到期日期", trigger: "blur" },
                valueDateTime: { required: true, message: "请选择产品起息日期", trigger: "blur" },

                
                circulation: { validator (r, val, c) {
                    if (
                        (typeof val === "number" && val >= 0) ||
                        val === ""
                    ) c();

                    else if (!/~[0-9]\d?$/.test(val)) {
                        c("只能输入正整数")
                    }

                    else c()
                }, trigger: "blur"},
                none: {validator: function (r,v,c) {
                    c();
                }}
            },
            roleList: [],
        }
    },

    computed: {
        isUpdate () {
            return !!this.item?.id
        },
        titleText () {
            return this.isUpdate ? '编辑产品' : '新增产品'
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                for (let key in item) {
                    const _key = key.toCamel();
                    this.PB[_key] = item[key] == null ? "" : item[key]
                }
                this.copy_valueDateTime = item.valueDateTime||''
                this.viewIsReady = true;
                if(item.id){
                    this.queryHasFullPayCount()
                }
            }
        }
    },

    methods: {
        async queryHasFullPayCount(){
             const loadingRef = this.$loading({
                target: ".product-edit .el-dialog"
            });
             const res = await this.$axios({
                    method: "post",
                    url: "/api/product/queryHasFullPayCount",
                    data:{
                        id:this.item.id
                    }
                })

                if (res.code === 2000) {
                    this.isFlag = res.data.flag>0?true:false
                    loadingRef && loadingRef.close();
                    // this.$emit("change");

                } else if (res.code !== 1003) 
                    throw res;

        },
        handleCloseB(){
            this.tipDia = false
        },
        handleCloseT(){
            this.tipDia = false
        },
        handleSure(){
            this.tipDia = false
            this.handleSave()
        },
        onEndtimeChange (val) {
            // if (val && !this.PB.realEndTime) {
                this.PB.realEndTime = val
            // }
        },
        handleSaveBefore(){
            console.log(1);
            // 编辑起息日期时需要提示“该产品已存在全款齐的订单，您确认要修改该产品的起息日期么？
            this.PB.valueDateTime = this.PB.valueDateTime||''
            if(this.item.id && (this.PB.valueDateTime != this.copy_valueDateTime) && this.isFlag){
                this.tipDia = true
            }else{
                this.handleSave()
            }
        },
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".product-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const data = new FormData();
                for (let key in this.PB) {
                    const value = this.PB[key];
                    if (key != "nanoid") data.append(key, value == null ? "" : value)
                }
                console.log(data.entries());
                const res = await this.$axios({
                    method: "post",
                    url: "/api/product/saveProduct",
                    headers: {
                        "Accept": "*/*",
                        "Content-Type": "multipart/form-data"
                    },
                    data
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.viewIsReady = false;
            for (let key in this.PB) {
                this.PB[key] = "";
            }
            this.PB.iscom = 0;
            document.querySelector(".product-edit .el-dialog__body").scrollTo(0,0);
            this.$refs.form.clearValidate();
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .hint {
        margin-left: 0 !important;
        color: orangered !important;
    }
</style>