import AddStaffItem from "./AddStaffItem.vue";

export default {
    name: 'CommissionDetail',
    components: {AddStaffItem},
    data () {
        return {
            id               : undefined,
            showAddWindow    : false,
            tableData        : [],
            reductionData: [],

            orderCommInfo    : [],
            postEmpList      : [],
            auth: {
                save  : true,
                delete: true,
                saveOrderReduction: true,
                batchDelete: true,
            },
            viewIsReady: false,
            PB: {
                bili: '',
                // commtime: "",
                commMoney: "",
                remark: "",
            },
            rules: {
                bili: [
                    { required: true, message: "请填写结佣比例" },
                    {
                        validator(r, val, c) {
                            if (typeof val === "number") c()
                            else if (!/^\d{1,11}(\.\d{1,2})?$/.test(val)) {
                                c("请输入数字, 最多包含两位小数")
                            }
                            else c()
                        }
                    }
                ],
                commMoney: [
                    { required: true, message: "请填写结佣金额" },
                    {
                        validator(r, val, c) {
                            if (typeof val === "number") c()
                            else if (!/^\d{1,11}(\.\d{1,2})?$/.test(val)) {
                                c("请输入数字, 最多包含两位小数")
                            }
                            else c()
                        }
                    }
                ]
            },
            orderPostListEnum: [],
            placeItem: '',
            clickrow: '',
            pageNum: 1,
            pageSize: 10,
            total: 0,
            activeName: 'first',

            pageNum_re: 1,
            pageSize_re: 10,
            total_re: 0,
            addReDia: false,

            reData: {
                reductionTime: '',
                list: [],
                commmoney: '',
                remark: '',

            },
            reRule: {
                reductionTime: { required: true, message: "请选择扣回日期" },
                commmoney: {
                    validator: function (r, v, cb) {
                        const isFine = /^\d+(\.\d{1,2})?$/.test(v);
                        if (isFine) cb();
                        else cb("请输入正确的金额, 允许两位小数");
                    },
                },
                none: {
                    validator: function (r, v, cb) {
                        cb();
                    },
                },


            },
            multipleSelection: [],
        }
    },

    created () {
        const id = Number(this.$route.params.id);
        if (id) {
            this.id = id;
            this.fetchData()
        }
        else {console.log("初始化失败, 没有orderid");}

        this.auth.save   = this.$hasAuthFor("api/orderComm/saveOrderComm");
        this.auth.delete = this.$hasAuthFor("api/orderComm/deleteOrderComm");
        this.auth.saveOrderReduction = this.$hasAuthFor("api/orderComm/saveOrderReduction");
        this.auth.batchDelete = this.$hasAuthFor("api/orderComm/batchDeleteCommRecord");

    },

    methods: {
        // 批量删除
        async handleDelButton(item) {
            let loadingRef;
            if (this.multipleSelection.length <= 0) {
                return this.$message({
                    message: "请勾选操作数据",
                    type: "error",
                });
            } else {
                let ids = []
                this.multipleSelection.forEach(element => {
                    ids.push(element.id)
                });
                try {
                    await this.$confirm("确定要删除吗", "系统提示")
                    loadingRef = this.$loading();
                    const res = await this.$axios({
                        url: "/api/orderComm/batchDeleteCommRecord",
                        method: "post",
                        data: {
                            ids: ids.join()
                        }
                    })
                    if (res.code === 2000) {
                        this.$message({
                            message: "删除成功",
                            type: "success"
                        });
                        loadingRef && loadingRef.close();
                        this.fetchData();

                    } else if (res.code !== 1003)
                        throw res;

                } catch (reason) {
                    if (reason !== "cancel") {
                        this.$message({
                            message: reason.msg || "删除失败",
                            type: "warning"
                        });
                    }
                    loadingRef && loadingRef.close();
                }
            }
        },

        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleInput(value, data) {
            // console.log("change", value, data)
            if (!!value.trim() && !data.checked) {
                data.checked = true;
            }
        },
        // 获取添加扣回 — 员工回显
        async addOrderReduction() {
            const loading = this.$loading();
            try {
                const res = await this.$axios({
                    url: "/api/orderComm/addOrderReduction",
                    method: "post",
                    data: { orderId: this.orderCommInfo.orderId }
                });

                if (res.code === 2000) {
                    res.data.orderComms.forEach(element => {
                        element.checked = false;
                        element.commmoney = element.commAllMoney;

                    });
                    this.reData.list = res.data.orderComms;
                    this.addReDia = true;
                    loading.close();

                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },
        // 添加扣回
        handleAddReButton() {
            this.addOrderReduction()
        },

        handleReClose() {
            this.reData.reductionTime = "";
            this.reData.list = [];
            this.reData.commmoney = "";
            this.reData.remark = "";
            this.addReDia = false;
            setTimeout(() => {
                this.$refs.reform.clearValidate();
            }, 20);
        },
        //添加 扣回佣金 保存
        async handleReSave() {
            // this.handleReClose()
            const loadingRef = this.$loading({
                target: ".add-re .el-dialog",
            });

            try {
                await this.$refs.reform.validate();
                const checkedList = this.reData.list.filter(
                    ({ checked }) => checked
                );
                if (!checkedList.length) {
                    loadingRef.close();
                    return this.$message({
                        message: "请勾选员工",
                        type: "error",
                    });
                }
                let savelist = []
                checkedList.forEach(element => {
                    savelist.push({
                        empId: element.empId,
                        reductionMoney: element.commmoney
                    })


                });

                const { reductionTime, remark } = this.reData;
                const res = await this.$axios({
                    url: "/api/orderComm/saveOrderReduction",
                    method: "post",
                    data: {
                        reductionTime,
                        remark,
                        orderId: this.orderCommInfo.orderId,
                        orderReductionStr: JSON.stringify(savelist),
                    },
                });

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                    });
                    loadingRef && loadingRef.close();
                    this.handleReClose();
                    this.fetchData();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "操作失败",
                        type: "error",
                    });
                console.warn(reason);
                loadingRef && loadingRef.close();
            }

        },
        // 点击返回
        handleNavigationBack() {
            this.$router.back();
        },
        handleOrderDetail () {
			const url = new URL(location.href);
			url.hash = `/core/order/detail/${this.orderCommInfo.orderId}`
			window.open(url)
        },

        // 获取页面数据
        async fetchData() {
			const loading = this.$loading();

			try {
				const res = await this.$axios({
					url: "/api/orderComm/queryOrderCommInfo",
					method: "post",
					data: { applyId: this.id }
				});

				if (res.code === 2000) {

                    // res.data.orderCommInfo.isAddCalculate = 1
                    // res.data.orderCommInfo.addCalculateSourceMoney = 200000
                    // res.data.orderCommInfo.addCalculateMoney = 100000
                    // res.data.orderCommInfo.addCalculateBili = 50

                    const {
                        orderCommInfo,   // 订单信息
                        postEmpList,     // 订单员工信息
                        orderPostListEnum,
                    } = res.data;
                    this.orderPostListEnum = orderPostListEnum;
                    this.orderCommInfo = orderCommInfo;
                    this.postEmpList   = postEmpList;
                    await this.fetchCommList();
                    await this.fetchReductionList();
					loading.close();

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();	
			}
        },

        async fetchCommList () {
            try {
                const res = await this.$axios({
                    method: "post",
                    url: "/api/orderComm/queryOrderCommList",
                    data: {
                        orderId: this.orderCommInfo.orderId,
                        pageNum: this.pageNum,
                        pageSize: this.pageSize,
                    }
                })

                if (res.code === 2000) {
                    const list = res.data.orderCommList;
                    this.tableData = list; 
                    this.total = res.data.count

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "获取记录失败",
                    type: "error"
                });
            }
        },
        async fetchReductionList() {
            try {
                const res = await this.$axios({
                    method: "post",
                    url: "/api/orderComm/queryReductionList",
                    data: {
                        orderId: this.orderCommInfo.orderId,
                        pageNum: this.pageNum_re,
                        pageSize: this.pageSize_re,
                    }
                })

                if (res.code === 2000) {
                    const list = res.data.orderReductionList;
                    this.reductionData = list;
                    this.total_re = res.data.count

                } else if (res.code !== 1003)
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "获取记录失败",
                    type: "error"
                });
            }
        },


        // 添加结佣
        handleAddButton () {
            this.showAddWindow = true;
        },

        // 删除结佣
        async handleDelete (item) {
            let loadingRef;
			try {
                await this.$confirm("确定要删除吗", "系统提示")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/orderComm/deleteOrderComm",
                    method: "post",
                    data: { orderCommId: item.id }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        // 删除扣回记录
        async handleDelete_re(item) {
            let loadingRef;
            try {
                await this.$confirm("确定要删除吗", "系统提示")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/orderComm/deleteCommReduction",
                    method: "post",
                    data: { id: item.id }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        // 编辑
        handleEdit(row) {
            this.clickrow = row
            this.PB.bili = this.clickrow.bili
            this.PB.commMoney = this.clickrow.commMoney
            this.PB.remark = this.clickrow.remark
            this.viewIsReady = true

        },
        handleClose() {
            this.resetData();
            this.viewIsReady = false

        },
        resetData() {
            this.PB.commtime = "";
            this.PB.commmoney = "";
            this.PB.remark = "";
            this.$refs.form.clearValidate();
        },
        async handleSave() {
            console.log(123123);
            const loadingRef = this.$loading({
                target: ".settle-commission .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/orderComm/updateOrderComm",
                    method: "post",
                    data: {
                        id: this.clickrow.id,
                        bili: this.PB.bili,
                        remark: this.PB.remark,
                        commmoney: this.PB.commMoney,

                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    // this.$emit("change");
                    this.fetchData()
                    this.handleClose();

                } else if (res.code !== 1003)
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
        // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.fetchCommList();
        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.fetchCommList();
        },
        // 翻页相关
        handlePageSizeChange_re(size) {
            this.pageSize_re = size;
            this.fetchReductionList();
        },
        // 翻页相关
        handlePageNoChange_re(no) {
            this.pageNum_re = no;
            this.fetchReductionList();
        },
    }
}