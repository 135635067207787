import forList from "@/mixin/forList";
import {searchTypeEnum, stateEnum} from "./config";

export default {
    name: "StaffList",
	noFetchFirst: true,
    mixins: [ forList ],

    data() {
        return {
			searchBarLabelWidth: 110, // 不会改变，不用追踪

            SP_: {
				search_type: 1,
				search_value: "",
                search_region: "",   // 大区
                search_dept: "",     // 部门
                search_post: "",     // 岗位
                state: "",           // 状态
            },
			searchTypeEnum,
            stateEnum,
			departmentList: [],    // 大区、部门树（2层）
            subDepartmentList: [], // 第2层 选中大区后上面数据派生出来的
            postList: [],          // 岗位的选项
            manorgnamesData: null,

            clickRowIndex: 0,
            viewIsReady: false,
            PB: {
                channelName: "",
                newEmpId: ""

            },
            rules: {
                channelName: [
                    { required: true, message: "请选择接收员工" },
                    { validator: this.threeValidator, trigger: "blur" }
                ]

            }
        };
    },
    watch: {
        'PB.channelName': {
            handler: function (val, oldVal) {
                console.log(val);
                if (val == '') {
                    this.PB.newEmpId = ''
                }
            },
            deep: true,
        },
    },

    methods: {
        hidePhoneNumber(phoneNumber) {
            const str = phoneNumber.toString();
            return str.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
        },
        async querySearchThree(queryString, cb, type) {
            try {
                // /employee/queryLikeEmpList
                const res = await this.$axios({
                    url: "/api/employee/queryLikeEmpList",
                    method: "post",
                    data: {
                        searchName: queryString,
                        type: 2,
                    }
                });

                if (res.code === 2000) {
                    cb(res.data.empList.map(emp => {
                        emp.value = [emp.name, emp.reginName, emp.postName].filter(Boolean).join(" - ")
                        return emp;
                    }));

                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("模糊查询失败", reason);
                cb([]);
            }

        },
        handleAutocompleteChangeChannel(e, key) {
            if (e.trim() !== "") return;
            this.PB.channelName = "";
            this.PB.newEmpId = "";
        },
        handleSelectChannel(item, key) {
            console.log(item);
            this.PB.newEmpId = item.id;
            this.PB.channelName = item.name;
        },
        resetData() {
            this.PB.channelName = "";
            this.$refs.form.clearValidate();
        },

        handleClose() {
            this.resetData();
            this.viewIsReady = false;
            setTimeout(() => {
                this.$refs.form.clearValidate();
            }, 20);
            this.$emit("update:item", null);
        },
        async handleSave() {
            try {
                await this.$refs.form.validate();
                this.handleUpdateOld()
            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
            }
        },

		async fetchData() {
			const loading = this.$loading();

            this.fetchConfig();
            this.fetchDepartment();
			try {
				const res = await this.$axios({
					url: "/api/employee/getEmpList",
					method: "post",
					data: {...this.extractParams()}
				})
				if (res.code === 2000) {
                    this.total = res.data.count;
                    this.tableData = res.data.employeelist;

					loading.close();
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();
			}
		},

		// 获取岗位配置参数
		async fetchConfig () {
			if (this.postList.fine) return;
			try {
				const res = await this.$axios({
					url: "/api/employee/queryPostAll",
					method: "post"
				})
				if (res.code === 2000) {
					const list = res.data.postList;
					list.fine = true;
					this.postList = list;

				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取岗位配置参数出错", reason);
			}
		},

        // 获取大区、部门
		async fetchDepartment (pid = 0) {
            const parent = pid ? this.departmentList.find(it =>
                    it.id === pid) : null;
            if (parent && parent.children) return;

			try {
				const res = await this.$axios({
					url: "/api/employee/queryOrgByPId",
					method: "post",
                    data: {pid}
				})
				if (res.code === 2000) {
					const list = res.data.regionList;
                    if (pid) parent.children = list;
                    else this.departmentList = list;

				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取选项参数出错", reason);
			}
		},

        // 当选中的大区时时，重置部门
        handleSupChange (val) {
            this.SP_.search_dept = "";
            this.fetchDepartment(val)
            .then(() => {
                const item = this.departmentList.find(it =>
					it.id === val);
				this.subDepartmentList = item.children || [];
            });
        },
        handleUpdate(index) {
            this.clickRowIndex = index
            this.viewIsReady = true;
        },

		// 点击表格行操作按钮: 离职
        async handleUpdateOld(index) {
            let _this = this
            let loadingRef, data = this.tableData[this.clickRowIndex];
			try {
				await this.$confirm("确定要离职吗", "系统提示");
				loadingRef = this.$loading();
				const res = await this.$axios({
                    url: "/api/employee/updateAccountState",
					method: "post",
					data: {
                        accId: data.accId,
                        state: 2,
                        newEmpId: _this.PB.newEmpId
                    }
				})
				if (res.code === 2000) {
					this.$message({
						message: "操作成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
                    _this.handleClose()
                    _this.fetchData();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {

				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "操作失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
		},

        // 查看详细参数
        handleManorgnames(index) {
            const item = this.tableData[index];
            this.manorgnamesData = item.manorgnames.split(",").filter(Boolean)
        },
		handleManorgnamesclose () {
			this.manorgnamesData = null
		},

        clearSP_trim () {
            this.SP_.search_type = 1
        },

		// 点击导出订单
        async handleExportBtn () {
			let loadingRef,
				url = "/api/employee/downEmpInfo";
			try {
				await this.$confirm("确定导出吗", "系统提示")
				loadingRef = this.$loading();

                const data = this.extractParams(true);
                const res = await this.$axios({
                    method: "post", url, data })

				if (res.code === 2000) {
					this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
					loadingRef && loadingRef.close();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
				}
				loadingRef && loadingRef.close();
			}
		}
    },

	created () {
		let follow = localStorage.getItem("follow");
        if (follow) follow = JSON.parse(follow);
		if (follow?.empCode && !follow.token) {
			const {empCode} = follow;
			this.SP.search_type = 2;
			this.SP.search_value = empCode;
			this.updateSPtoSP_()
		}

		this.fetchData();
		this.auth.update = this.$hasAuthFor("api/employee/updateAccountState");
		this.auth.export = this.$hasAuthFor("api/employee/downEmpInfo");
	}
};